var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useRef, useState } from 'react';
import { isActorWithState } from './useActor';
import { getServiceSnapshot } from './useService';
function isService(actor) {
    return 'state' in actor && 'machine' in actor;
}
var defaultCompare = function (a, b) { return a === b; };
var defaultGetSnapshot = function (a) {
    return isService(a)
        ? getServiceSnapshot(a)
        : isActorWithState(a)
            ? a.state
            : undefined;
};
export function useSelector(actor, selector, compare, getSnapshot) {
    if (compare === void 0) { compare = defaultCompare; }
    if (getSnapshot === void 0) { getSnapshot = defaultGetSnapshot; }
    var _a = __read(useState(function () { return selector(getSnapshot(actor)); }), 2), selected = _a[0], setSelected = _a[1];
    var selectedRef = useRef(selected);
    useEffect(function () {
        var updateSelectedIfChanged = function (nextSelected) {
            if (!compare(selectedRef.current, nextSelected)) {
                setSelected(nextSelected);
                selectedRef.current = nextSelected;
            }
        };
        var initialSelected = selector(getSnapshot(actor));
        updateSelectedIfChanged(initialSelected);
        var sub = actor.subscribe(function (emitted) {
            var nextSelected = selector(emitted);
            updateSelectedIfChanged(nextSelected);
        });
        return function () { return sub.unsubscribe(); };
    }, [selector, compare]);
    return selected;
}
